function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"markdown-body"},[_c('h1',[_vm._v("frontmatter-markdown-loader-vue-sample")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('h2',[_vm._v("Project setup")]),_vm._v(" "),_c('p',[_c('npm-or-yarn',[_vm._v("If works as a Vue component, this line should disappear. Try in your local!")])],1),_vm._v(" "),_c('h3',[_vm._v("Compiles and hot-reloads for development")]),_vm._v(" "),_vm._m(1),_vm._v(" "),_c('h3',[_vm._v("Compiles and minifies for production")]),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('h2',[_vm._v("Here's code snipet which should not be compiled as Vue's template")]),_vm._v(" "),_vm._m(3),_vm._v(" "),_vm._m(4),_vm._v(" "),_vm._m(5),_vm._v(" "),_c('h2',[_vm._v("Asset transformation")]),_vm._v(" "),_vm._m(6)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',[_c('li',[_vm._v("Sample Vue app to use "),_c('a',{attrs:{"href":"https://github.com/hmsk/frontmatter-markdown-loader"}},[_vm._v("frontmatter-markdown-loader")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pre',[_c('code',{pre:true},[_vm._v("npm run serve\n")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pre',[_c('code',{pre:true},[_vm._v("npm run build\n")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("Reported in "),_c('a',{attrs:{"href":"https://github.com/hmsk/frontmatter-markdown-loader/issues/4"}},[_vm._v("Issue #4")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pre',[_c('code',{pre:true,attrs:{"class":"language-html"}},[_vm._v("<html lang=\"{{ app()->getLocale() }}\">\n  <body>\n    {{ something }}\n  </body>\n</html>\n")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v("In "),_c('a',{attrs:{"href":"https://github.com/hmsk/frontmatter-markdown-loader/issues/6"}},[_vm._v("Issue #6")]),_vm._v(", reported "),_c('code',{pre:true},[_vm._v("inline code")]),_vm._v(" is not working correctly.")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('img',{attrs:{"src":require("./src/assets/logo.png"),"alt":"This image should be rendered on Vue"}})])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }